var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"my-2 mx-0 pa-0",staticStyle:{"width":"100%"}},[_c('v-card',{staticClass:"px-4 py-5 flex-fill",attrs:{"flat":_vm.flat,"outlined":_vm.flat}},[_c('v-card-title',{staticClass:"ml-4 mb-4 pa-0 font-weight-bold"},[_vm._v("Missions")]),_c('v-card-text',{staticClass:"mb-0 pb-0 pt-0"},[(_vm.missions.length)?_c('div',{staticClass:"d-flex align-start flex-col"},[_c('div',{staticClass:"d-flex align-center flex-wrap",staticStyle:{"width":"100%"}},_vm._l((_vm.missions),function(mission){return _c('v-card',{key:mission.id,staticClass:"mr-3 mb-2",staticStyle:{"width":"23%"},attrs:{"to":{
              name: _vm.can('view_missions') ? _vm.$routes.MissionDetail : _vm.$routes.OpportunityDetail,
              params: {
                id: _vm.can('view_missions')
                  ? mission.id
                  : mission.missionStaffingPlacement.missionStaffingPosition.id,
              },
            },"outlined":""}},[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-between align-center"},[_c('p',{staticClass:"ma-0 pa-0 text--black font-weight-bold text-truncate",staticStyle:{"font-size":"16px","max-width":"60%","color":"#181818"}},[_vm._v(" "+_vm._s(mission.name)+" ")]),(mission.state)?_c('v-chip',{attrs:{"x-small":"","color":mission.state === 'completed' ? '#E9FFE5' : '#FFF8E5',"text-color":mission.state === 'completed' ? '#179717' : '#F2994A'}},[_vm._v(" "+_vm._s(_vm.getMissionStatus(mission.state))+" ")]):_vm._e()],1),(mission.missionLead)?_c('p',{staticClass:"ma-0 pa-0",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(mission.missionLead.client ? mission.missionLead.client.name : mission.missionLead.clientName)+" ")]):_vm._e()])],1)}),1),_c('text-button',{attrs:{"to":{
            name: _vm.can('view_missions') ? _vm.$routes.Missions : _vm.$routes.MyMissions,
          }}},[_vm._v("Show All")])],1):_c('div',{staticClass:"d-flex py-5 flex-col text-center align-center justify-center",staticStyle:{"height":"120px"}},[_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v("No missions yet.")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }